<template>
  <div class="container">
    <div class="container mb-2">
      <!-- <v-divider class="mx-4" color="black"></v-divider> -->
      <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">
        non alcoholic
      </h1>
      <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
    <!-- <backtogetraenke></backtogetraenke> -->
    <v-card class="mt-2">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.getraenk="{ item }">
          <b>{{ item.getraenk }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtogetraenke from './Backtogetraenke.vue';
export default {
  // components: { Backtogetraenke },
  name: 'Alkoholfrei',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Getränk',
          value: 'getraenk',
        },
        { text: 'Menge', value: 'menge' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          getraenk: 'Coca Cola',
          menge: 'Fl. 0,33 l',
          preis: '4,20 €',
        },
        {
          id: 2,
          getraenk: 'Cola Zero',
          menge: 'Fl. 0,33 l',
          preis: '4,20 €',
        },
        {
          id: 3,
          getraenk: 'Almdudler | sparkling herbal lemonade',
          menge: 'Fl. 0,33 l',
          preis: '4,20 €',
        },
        {
          id: 4,
          getraenk: 'Fanta',
          menge: 'Fl. 0,33 l',
          preis: '4,20 €',
        },
        {
          id: 5,
          getraenk: 'Sprite',
          menge: 'Fl. 0,33 l',
          preis: '4,20 €',
        },
        {
          id: 6,
          getraenk: 'Eistee Zitrone | icetea, lemon',
          menge: 'Fl. 0,33 l',
          preis: '4,20 €',
        },
        {
          id: 7,
          getraenk: 'Eistee Pfirsich | icetea, peach',
          menge: 'Fl. 0,33 l',
          preis: '4,20 €',
        },
        {
          id: 8,
          getraenk: 'Römerquelle prickelnd/still | sparkling or still water',
          menge: 'Fl. 0,33 l',
          preis: '3,80 €',
        },
        {
          id: 9,
          getraenk: 'Römerquelle prickelnd/still | sparkling or still water',
          menge: 'Fl. 0,75 l',
          preis: '7,90 €',
        },
        {
          id: 10,
          getraenk: 'Red Bull',
          menge: 'Dose 0,25 l',
          preis: '5,10 €',
        },
        {
          id: 11,
          getraenk: 'Apfelsaft naturtrüb | apple juice, unfiltered',
          menge: '0,25 l',
          preis: '4,20 €',
        },
        {
          id: 12,
          getraenk: 'Cappy Marillensaft | apricot juice',
          menge: 'Fl. 0,25 l',
          preis: '4,10 €',
        },
        {
          id: 13,
          getraenk: 'Cappy Johannisbeerensaft | black current juice',
          menge: 'Fl. 0,25 l',
          preis: '4,10 €',
        },
        {
          id: 14,
          getraenk: 'Cappy Orangensaft | orange juice',
          menge: 'Fl. 0,25 l',
          preis: '4,10 €',
        },
        {
          id: 15,
          getraenk: 'Säfte mit Soda gespritzt',
          menge: '0,50 l',
          preis: '5,20 €',
        },
        {
          id: 16,
          getraenk: 'Säfte mit Wasser gespritzt',
          menge: '0,50 l',
          preis: '4,60 €',
        },
        {
          id: 17,
          getraenk: 'Orangensaft, gepresst',
          menge: '0,125 l',
          preis: '2,70 €',
        },
        {
          id: 19,
          getraenk: 'Tonic',
          menge: '0,25 l',
          preis: '4,40€',
        },
        {
          id: 20,
          getraenk: 'Bitter Lemon',
          menge: '0,25 l',
          preis: '4,40 €',
        },
        {
          id: 21,
          getraenk: 'Sodawasser',
          menge: '0,25 l',
          preis: '2,30 €',
        },
        {
          id: 22,
          getraenk: 'Sodawasser',
          menge: '0,50 l',
          preis: '4,20 €',
        },
        {
          id: 23,
          getraenk: 'Sodawasser-Zitrone, Himbeer, Holunder',
          menge: '0,25 l',
          preis: '3,00 €',
        },
        {
          id: 24,
          getraenk: 'Sodawasser-Zitrone, Himbeer, Holunder',
          menge: '0,50 l',
          preis: '4,80 €',
        },
        {
          id: 25,
          getraenk: 'Leitungswasser-Zitrone, Himbeer, Holunder',
          menge: '0,50 l',
          preis: '3,50 €',
        },
        {
          id: 26,
          getraenk: 'Glas Wiener Wasser | glass of water',
          menge: '0,25 l',
          preis: '0,50 €',
        },
        {
          id: 27,
          getraenk: 'Portion Zitrone',
          menge: '',
          preis: '0,70 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
