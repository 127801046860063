<template>
  <div class="home">
    <div class="container d-flex flex-wrap">
      <backtohome></backtohome>
      <v-tabs centered color="green">
        <!-- Frühstück -->
        <v-tab href="#fruehstueck">Frühstück | Breakfast</v-tab>
        <v-tab-item value="fruehstueck">
          <fruehstueck></fruehstueck>
        </v-tab-item>
        <!-- Frühstück-Extras -->
        <v-tab href="#extras">Frühstück-Extras</v-tab>
        <v-tab-item value="extras">
          <fruehstueckextras></fruehstueckextras>
        </v-tab-item>
        <!-- Snacks -->
        <v-tab href="#snacks">Snacks</v-tab>
        <v-tab-item value="snacks">
          <snacks></snacks>
        </v-tab-item>
        <!-- Omlette -->
        <v-tab href="#omlette">Omlette & Leichte Speisen</v-tab>
        <v-tab-item value="omlette">
          <omelette></omelette>
        </v-tab-item>
        <!-- Suppen -->
        <v-tab href="#suppe">Suppen-Soups</v-tab>
        <v-tab-item value="suppe">
          <suppe></suppe>
        </v-tab-item>
        <!-- Hauptspeise -->
        <v-tab href="#hauptspeise">Hauptspeisen | main dishes</v-tab>
        <v-tab-item value="hauptspeise">
          <hauptspeise></hauptspeise>
        </v-tab-item>
        <!-- Beilagen -->
        <v-tab href="#beilagen">Beilagen | side dishes</v-tab>
        <v-tab-item value="beilagen">
          <beilagen></beilagen>
        </v-tab-item>
        <!-- Nachspeise -->
        <v-tab href="#nachspeise">Nachspeise | dessert</v-tab>
        <v-tab-item value="nachspeise">
          <nachspeise></nachspeise>
        </v-tab-item>
        <!-- TEE -->
        <!-- <v-tab href="#tee">Tee | tea</v-tab>
        <v-tab-item value="tee">
          <tee></tee>
        </v-tab-item> -->
        <!-- SCHOKOLADE -->
        <!-- <v-tab href="#schokolade">Schokolade | hot chocolate</v-tab>
        <v-tab-item value="schokolade">
          <schokolade></schokolade>
        </v-tab-item> -->
        <!-- JUGENDGETRÄNK -->
        <!-- <v-tab href="#jugendgetraenk">Jugendgetränk | Softdrinks</v-tab>
        <v-tab-item value="jugendgetraenk">
          <jugendgetraenke></jugendgetraenke>
        </v-tab-item> -->
        <!-- KAFFEE -->
        <!-- <v-tab href="#kaffee">Kaffee | coffee</v-tab>
        <v-tab-item value="kaffee">
          <kaffee></kaffee>
        </v-tab-item> -->
        <!-- ALKOHOLFREI -->
        <!-- <v-tab href="#alkoholfrei">Alkoholfrei | non alcoholic</v-tab>
        <v-tab-item value="alkoholfrei">
          <alkoholfrei></alkoholfrei>
        </v-tab-item> -->
        <!-- BIER -->
        <!-- <v-tab href="#bier">Bier | beer</v-tab>
        <v-tab-item value="bier">
          <bier></bier>
        </v-tab-item> -->
      </v-tabs>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
import Fruehstueck from './Fruehstueck.vue';
import Fruehstueckextras from './Fruehstueckextras.vue';
import Snacks from './Snacks.vue';
import Omelette from '../views/Omelette.vue';
import Suppe from '../views/Suppe.vue';
import Hauptspeise from '../views/Hauptspeise.vue';
import Beilagen from '../views/Beilagen.vue';
import Nachspeise from './Nachspeise.vue';
// import Tee from './Tee.vue';
// import Schokolade from '../views/Schokolade.vue';
// import Jugendgetraenke from './Jugendgetraenke.vue';
// import Kaffee from '../views/Kaffee.vue';
// import Alkoholfrei from '../views/Alkoholfrei.vue';
// import Bier from '../views/Bier.vue';
export default {
  components: {
    Backtohome,
    Fruehstueck,
    Fruehstueckextras,
    Snacks,
    Omelette,
    Suppe,
    Hauptspeise,
    Beilagen,
    Nachspeise,
    // Tee,
    // Schokolade,
    // Jugendgetraenke,
    // Kaffee,
    // Alkoholfrei,
    // Bier,
  },
  name: 'Kontakt',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-timeline:before {
  background: white;
}
</style>
