<template>
  <div class="home">
    <div class="container d-flex flex-wrap">
      <backtohome></backtohome>
      <v-tabs centered color="green">
        <!-- Tee -->
        <v-tab href="#tee">Tee | tea</v-tab>
        <v-tab-item value="tee">
          <tee></tee>
        </v-tab-item>
        <!-- Schokolade -->
        <v-tab href="#schokolade">Schokolade | hot chocolate</v-tab>
        <v-tab-item value="schokolade">
          <schokolade></schokolade>
        </v-tab-item>
        <!-- Jugendgetränk -->
        <v-tab href="#jugendgetraenk">Jugendgetränk | Softdrinks</v-tab>
        <v-tab-item value="jugendgetraenk">
          <jugendgetraenke></jugendgetraenke>
        </v-tab-item>
        <!-- Kaffee -->
        <v-tab href="#kaffee">Kaffee | coffee</v-tab>
        <v-tab-item value="kaffee">
          <kaffee></kaffee>
        </v-tab-item>
        <!-- Alkoholfrei -->
        <v-tab href="#alkoholfrei">Alkoholfrei | non alcoholic</v-tab>
        <v-tab-item value="alkoholfrei">
          <alkoholfrei></alkoholfrei>
        </v-tab-item>
        <!-- Wein -->
        <v-tab href="#weine">Wein | wine</v-tab>
        <v-tab-item value="weine">
          <weine></weine>
        </v-tab-item>
        <!-- Spirituosen -->
        <v-tab href="#spirituosen">Spirituosen | Spirits</v-tab>
        <v-tab-item value="spirituosen">
          <spirituosen></spirituosen>
        </v-tab-item>
        <!-- Bier -->
        <v-tab href="#bier">Bier | beer</v-tab>
        <v-tab-item value="bier">
          <bier></bier>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
import Tee from './Tee.vue';
import Schokolade from '../views/Schokolade.vue';
import Jugendgetraenke from './Jugendgetraenke.vue';
import Kaffee from '../views/Kaffee.vue';
import Alkoholfrei from '../views/Alkoholfrei.vue';
import Weine from '../views/Weine.vue';
import Spirituosen from '../views/Spirituosen.vue';
import Bier from '../views/Bier.vue';
export default {
  components: {
    Backtohome,
    Tee,
    Schokolade,
    Jugendgetraenke,
    Kaffee,
    Alkoholfrei,
    Weine,
    Spirituosen,
    Bier
  },
  name: 'Kontakt',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-timeline:before {
  background: white;
}
</style>
