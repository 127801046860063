<template>
  <div class="home">
    <div class="container">
      <v-divider class="mx-4" color="black"></v-divider>
      <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">Warme Getränke</h1>
      <v-divider class="mx-4" color="black"></v-divider>
    </div>
    <div class="d-flex flex-wrap container">
      <backtogetraenke></backtogetraenke>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/kaffee"
            style="background-color: #212121"
          >
            <v-img src="../assets/images/kaffee.jpg" height="220px"></v-img>
            <v-card-title class="justify-center font-weight-thin">
              Kaffee | Coffee
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/tee"
            style="background-color: #212121"
          >
            <v-img src="../assets/images/tee.jpg" height="220px"></v-img>
            <v-card-title class="justify-center font-weight-thin">
              Tee
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/schokolade"
            style="background-color: #212121"
          >
            <v-img
              src="../assets/images/heisseschokolade.jpg"
              height="220px"
            ></v-img>
            <v-card-title class="justify-center font-weight-thin">
              Heiße Schokolade
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Backtogetraenke from './Backtogetraenke.vue';
export default {
  components: {
    Backtogetraenke,
  },
  name: 'Warmegetraenke',
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
