<template>
  <div class="home">
    <div style="width: 100%; height: 85px" class="container">
      <v-row>
        <v-col cols="48" sm="24" md="12">
          <p
            class="body-1"
            style="color: #0277bd; text-align: center; margin-top: 5px"
            href="tel:+4318779338"
          >
            Rufen Sie uns an und bestellen Sie ihr Essen telefonisch, welches
            Sie anschließen in 15-20 Minuten abholen können.<br /><a
              href="tel:+4318779338"
              style="text-decoration: none"
            >
              +431 8779338</a
            >
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex flex-wrap mt-10 container">
      <backtohome></backtohome>
        <v-tabs centered color="green">
          <!-- Abholung -->
          <v-tab href="#abholung">Abholung</v-tab>
          <v-tab-item value="abholung" class="mb-4"
            >
              <v-card class="d-block mx-auto mt-4" max-width="400" color="#212121">
                <v-card-title class="justify-center white--text headline">
                  ABHOLUNG
                </v-card-title>
                <v-card-subtitle class="text-center white--text overline mt-2">
                  WIR FREUEN UNS, IHR ESSEN FÜR SIE MIT FRISCHEN ZUTATEN
                  ZUBEREITEN ZU DÜRFEN!
                </v-card-subtitle>
                <v-timeline class="white--text">
                  <v-timeline-item>Anrufen</v-timeline-item>
                  <v-timeline-item class="text-right">
                    Das Essen bestellen
                  </v-timeline-item>
                  <v-timeline-item>Das Essen abholen</v-timeline-item>
                </v-timeline>
                <div class="m-2 text-center">
                  <v-btn
                    class="mx-auto"
                    color="success"
                    href="tel:+4318779338"
                  >
                    <span class="mr-2">Anrufen</span>
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </div>
              </v-card>
          </v-tab-item>
          <!-- Reservierung -->
          <v-tab href="#reservierung">Reservierung</v-tab>
          <v-tab-item value="reservierung" class="mb-4"
            >
              <v-card class="d-block mx-auto mt-4" max-width="400" color="#212121">
                <v-card-title class="justify-center white--text headline">
                  RESERVIERUNG
                </v-card-title>
                <v-card-subtitle class="text-center white--text overline mt-2">
                  WIR FREUEN UNS AUF IHR KOMMEN!
                </v-card-subtitle>
                <v-timeline class="white--text">
                  <v-timeline-item>Anrufen</v-timeline-item>
                  <v-timeline-item class="text-right">
                    Reservieren
                  </v-timeline-item>
                  <v-timeline-item>Kommen</v-timeline-item>
                </v-timeline>
                <div class="m-2 text-center">
                  <v-btn
                    class="mx-auto"
                    color="success"
                    href="tel:+4318779338"
                  >
                    <span class="mr-2">Anrufen</span>
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </div>
              </v-card>
          </v-tab-item>
        </v-tabs>

        <!-- <v-col cols="24" sm="12" md="6">
          <v-card class="d-block mx-auto" max-width="400" color="#212121">
            <v-card-title class="justify-center white--text headline">
              MJAM | LIEFERN LASSEN
            </v-card-title>
            <v-card-subtitle class="text-center white--text overline mt-2">
              SIE HABEN AUCH DIE MÖGLICHKEIT, GERICHTE VON UNS AUF MJAM ZU
              BESTELLEN.
            </v-card-subtitle>
            <v-timeline class="white--text" color="white">
              <v-timeline-item>Auf "Weiter zu Mjam" klicken</v-timeline-item>
              <v-timeline-item class="text-right">
                Das Essen bestellen
              </v-timeline-item>
              <v-timeline-item>Das Essen liefern lassen</v-timeline-item>
            </v-timeline>
            <div class="m-2 text-center">
              <v-btn
                class="mx-auto"
                color="#76FF03"
                href="https://www.mjam.net/restaurant/snkw/akaz?sem_tracker=15161031287&gclid=Cj0KCQiA-K2MBhC-ARIsAMtLKRsa5asRvZMmULySV_51tjZf0ZJAd9KqLYUrZ3ePSyAiens_qB5P-Y8aArynEALw_wcB"
              >
                <span class="mr-2 white--text">Weiter zu Mjam</span>
              </v-btn>
            </div>
          </v-card>
        </v-col> -->
        <!-- <v-col cols="24" sm="12" md="6">
          <v-card class="d-block mx-auto" max-width="400" color="#212121">
            <v-card-title class="justify-center white--text headline">
              LIEFERANDO | LIEFERN LASSEN
            </v-card-title>
            <v-card-subtitle class="text-center white--text overline mt-2">
              SIE HABEN AUCH DIE MÖGLICHKEIT, GERICHTE VON UNS AUF LIEFERANDO ZU
              BESTELLEN.
            </v-card-subtitle>
            <v-timeline class="white--text" color="white">
              <v-timeline-item
                >Auf "Weiter zu LIEFERANDO" klicken</v-timeline-item
              >
              <v-timeline-item class="text-right">
                Das Essen bestellen
              </v-timeline-item>
              <v-timeline-item>Das Essen liefern lassen</v-timeline-item>
            </v-timeline>
            <div class="m-2 text-center">
              <v-btn
                class="mx-auto"
                color="#F57C00"
                href="https://www.lieferando.at/speisekarte/akaz?k1111=k1111&gclid=CjwKCAiAhreNBhAYEiwAFGGKPJw83vqnAJr11IQhoQNb8PCcb37isuD6487uy3qaMEos5N3_LFiRsBoCV9YQAvD_BwE&gclsrc=aw.ds"
              >
                <span class="mr-2 white--text">Weiter zu LIEFERANDO</span>
              </v-btn>
            </div>
          </v-card>
        </v-col> -->
    </div>
    <div class="d-flex flex-wrap mt-10">
      <!-- <v-btn class="ma-2 d-block mx-auto" outlined color="indigo">
        Adresse
      </v-btn> -->
    </div>
    <div class="d-flex flex-wrap mt-10 d-block mx-auto">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.0680541890692!2d16.298932915819556!3d48.1860402557631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da7e3f39b7c75%3A0x7e1e5c9c4259d667!2sCaf%C3%A9%20am%20Platz!5e0!3m2!1sde!2sat!4v1639965531360!5m2!1sde!2sat"
        width="100%"
        height="600"
        style="border: 5"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<script>
import backtohome from './Backtohome.vue';
export default {
  components: {
    backtohome,
  },
  name: 'Kontakt',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-timeline:before {
  background: white;
}
</style>
