<template>
  <div class="home">
    <div class="container d-flex flex-wrap">
      <backtohome></backtohome>
      <v-tabs centered color="green">
        <!-- Kaffee -->
        <v-tab href="#kaffee">Kaffee | coffee</v-tab>
        <v-tab-item value="kaffee">
          <kaffee></kaffee>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import Backtohome from './Backtohome.vue';
import Kaffee from '../views/Kaffee.vue';
export default {
  components: {
    Backtohome,
    Kaffee,
  },
  name: 'Kontakt',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-timeline:before {
  background: white;
}
</style>
