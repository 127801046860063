<template>
  <div class="home">
    <v-carousel hide-delimiters cycle height="800px" max-width="100%">
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
    <div class="container">
      <backtohome></backtohome>
  <h1 class="text-center display-4 font-weight-thin font-italic mt-10">
      Über uns
    </h1>
    <div class="d-flex flex-wrap container">
      <v-row align="center" justify="center">
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <!-- <v-img
              height="300"
              src="../assets/images/restaurantfront.jpg"
            ></v-img> -->

            <v-card-title
              class="justify-center display-1 font-weight-bold white--text"
              color="#212121"
              >Das Ziel</v-card-title
            >

            <v-divider class="mx-4" color="white"></v-divider>
            <h3
              class="ml-2 font-weight-bold font-italic white--text mt-4 mb-4 mr-2"
            >
              In unserem Kaffeehaus im 13. Bezirk, Hietzing am Platz, unmittelbar neben dem majestätischen Schloss Schönbrunn, strebt unser Team täglich danach, 
              Ihnen einen ruhigen und angenehmen Rückzugsort von der Hektik des Alltags zu bieten. <br> Unsere Lage an diesem historischen Ort mit mehr als 300 Jahre 
              Geschichte spiegelt die Schönheit und den Zauber dieser königlichen Umgebung wider, und Sie haben die Möglichkeit, mitten in diesen Zauber Ihre Kaffee-
              oder Mahlzeit bei uns zu genießen.


            </h3>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card class="mx-auto my-12" max-width="350" color="#212121">
            <!-- <v-img
              height="300"
              src="../assets/images/restaurantinnen.jpg"
            ></v-img> -->

            <v-card-title
              class="justify-center display-1 font-weight-bold white--text"
              >Café am Platz</v-card-title
            >

            <v-divider class="mx-4" color="white"></v-divider>
            <h3
              class="ml-2 font-weight-bold font-italic white--text mt-4 mb-4 mr-2"
            >
              In unserem Kaffeehaus genießen Sie eine raffinierte Verbindung aus traditioneller österreichischer Küche, einer erlesenen Vielfalt
              an Kaffeesorten und einer verlockenden Auswahl an köstlichen Kuchen. <br>
              Und nachdem Sie Ihren Kaffee bestellt haben, können Sie sich von den meisterhaften Baristakünste unserer Experten überraschen lassen.            

            </h3>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-divider class="mx-4" color="black"></v-divider>
    <div class="container text-center">
      <p class="display-1 font-italic">
        Wir erwarten Sie mit großer Vorfreude und freuen uns darauf, für Sie köstliche Gerichte, unwiderstehliche Kuchen und erlesenen Kaffee zuzubereiten,
        alles aus frischen und sorgfältig ausgewählten Zutaten.
      </p>
    </div>
    <v-divider class="mx-4" color="black"></v-divider>
    <div class="container text-center">
      <p class="display-4 font-italic font-weight-thin">Gallerie</p>
    </div>
    <v-row align="center" justify="center">
      <v-col cols="24" sm="12" md="6">
        <v-card class="mx-auto my-12" max-width="350" color="#212121">
          <v-img height="300" src="../assets/images/kaffee.jpg"></v-img>
        </v-card>
      </v-col>
      <v-col cols="24" sm="12" md="6">
        <v-card class="mx-auto my-12" max-width="350" color="#212121">
          <v-img height="300" src="../assets/images/mozartschnitte.jpg"></v-img>
        </v-card>
      </v-col>
      <v-col cols="24" sm="12" md="6">
        <v-card class="mx-auto my-12" max-width="350" color="#212121">
          <v-img
            height="300"
            src="../assets/images/heisseschokolade.jpg"
          ></v-img>
        </v-card>
      </v-col>
            <v-col cols="24" sm="12" md="6">
        <v-card class="mx-auto my-12" max-width="350" color="#212121">
          <v-img
            height="300"
            src="../assets/images/schokotortevegan.jpg"
          ></v-img>
        </v-card>
      </v-col>
      <v-col cols="24" sm="12" md="6">
        <v-card class="mx-auto my-12" max-width="350" color="#212121">
          <v-img height="300" src="../assets/images/coffeetogo.jpg"></v-img>
        </v-card>
      </v-col>
      <v-col cols="24" sm="12" md="6">
        <v-card class="mx-auto my-12" max-width="350" color="#212121">
          <v-img
            height="300"
            src="../assets/images/coffeetogocreativeart.jpg"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
    </div>
</template>

<script>
import Backtohome from './Backtohome'
export default {
  components: {
    Backtohome,
  },
  name: 'Ueberuns',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/kaffeemaschine.jpg'),
        },
        {
          src: require('../assets/images/milchschaum.jpg'),
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 72px
  );
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>
