<template>
  <div class="container">
    <div class="container">    
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    <h1 class="text-center display-3 font-weight-thin mt-2">Snacks</h1>
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
            <div class="container text-center mb-2">
      <v-divider class="mx-4 mb-4" color="black"></v-divider>
      <p class="font-italic">
        <b>Täglich von 11:00 - 16:00 Uhr</b> <br />daily from 11 am - 4 pm
      </p>
      <v-divider class="mx-4 mb-4" color="black"></v-divider>
    </div>
    <!-- <backtospeisekarte></backtospeisekarte> -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="snacks"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.picture="{ item }">
          <v-img
            :src="require(`../assets/images/${item.picture}`)"
            max-width="150"
          ></v-img>
        </template>
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: { Backtospeisekarte },
  name: 'Snacks',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Speise',
          value: 'speise',
        },
        { text: 'Picture', value: 'picture' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      snacks: [
        {
          id: 1,
          speise: 'Schinken-Käse Toast | ham & cheese toast',
          picture: 'schinkenkaesetoast.png',
          allergene: '(A, G, O)',
          preis: '6,60 €',
        },
        {
          id: 2,
          speise:
            'Schinken-Käse Toast & Spiegelei | ham & cheese toast with an egg sunny side up',
          picture: 'platzhalter.png',
          allergene: '(C)',
          preis: '8,90 €',
        },
        {
          id: 3,
          speise: 'Riesen Schinken-Käse Toast | giant ham & cheese toast',
          picture: 'platzhalter.png',
          allergene: '(A, O)',
          preis: '12,50 €',
        },
        {
          id: 4,
          speise:
            'Frankfurter/Debreziner mit Senf, Kren & Semmel | sausage/spicy sausage with mustard, horseradish & roll',
          picture: 'platzhalter.png',
          allergene: '(A, M)',
          preis: '7,50 €',
        },
        {
          id: 5,
          speise:
            'Frankfurter/Debreziner mit Gulaschsaft & Semmel | sausage/spicy sausage with goulash sauce & roll',
          picture: 'platzhalter.png',
          allergene: '(A, C, G, L, M)',
          preis: '8,50 €',
        },
        {
          id: 6,
          speise: 'Ketchup, Mayonnaise oder Senf | ketchup, mayo or mustard',
          picture: 'platzhalter.png',
          allergene: '(O, C, M)',
          preis: '1,90 €',
        },
        {
          id: 7,
          speise: 'Kren',
          picture: 'platzhalter.png',
          allergene: '(/)',
          preis: '2,10 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
