<template>
  <v-app>
    <v-app-bar app color="white" height="110px" elevation="0" dark>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="green"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/images/cafeamplatzlogonew.png"
            transition="scale-transition"
            max-width="200"
          />
          <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
        </router-link>
      </div>

      <!-- <v-spacer></v-spacer>
      <router-link to="/speisekarte" style="text-decoration:none;">
        <span class="mr-2 grey--text">Speisekarte</span>
      </router-link>
      <v-spacer></v-spacer>
      <router-link to="/kontakt" style="text-decoration:none;">
        <span class="mr-2 grey--text">Bestellung</span>
      </router-link> -->
      
      <!-- INSTAGRAM LOGO -->
      <v-spacer></v-spacer>
      <!-- <v-btn
        icon
        href="https://www.instagram.com/akaz_asiatisches_restaurant/?hl=de"
      >
        <v-icon class="mr-4" color="green">mdi-instagram</v-icon>
      </v-btn> -->
      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>
        <!-- DATENSCHUTZ DIALOG -->
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
            Click Me
          </v-btn>
        </template> -->

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Datenschutzerklärung
          </v-card-title>
          <br />
          <v-card-text>
            Wir nutzen keine Cookies auf unserer Website. Personenbezogene Daten
            wie (z.B. IP-Adressen) werden von uns nicht verarbeitet. Unsere
            Website dient nur der grafischen Darstellung unserer Gerichte, um
            unseren Kunden einer erleichterten Vorstellung der Gerichte zu
            ermöglichen. Weiters dient die Website der digitalen Darstellung
            unserer physischen Speisekarte. Unsere Website ist eine reine
            "Front-End" Website und enthält keine "Back-End" Implementierungen.
            Weitere Informationen über die Website und die Verarbeitung Ihrer
            Daten finden Sie in unserer
            <a href="/Datenschutz" style="text-decoration: none;">
              Datenschutzerklärung.</a
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Ich verstehe
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="position: fixed"
      color="grey darken-4"
    >
      <v-list-item to="/" color="green">
        <v-list-item-content>
          <v-list-item-title class="white--text body-1"
            >Café am Platz</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="white--text">
          <v-divider color="white"></v-divider>
          <v-list-item to="/speisekartenew">
            <v-list-item-title class="white--text body-2 font-weight-thin"
              >Speisekarte</v-list-item-title
            >
          </v-list-item>
          <v-divider color="white"></v-divider>
          <v-list-item to="/getraenkekartenew">
            <v-list-item-title class="white--text body-2 font-weight-thin"
              >Getränkekarte</v-list-item-title
            >
          </v-list-item>
          <v-divider color="white"></v-divider>
          <v-list-item to="/kontakt">
            <v-list-item-title class="white--text body-2 font-weight-thin"
              >Bestellung</v-list-item-title
            >
          </v-list-item>
          <v-divider color="white"></v-divider>

          <v-list-item to="/oeffnungszeiten">
            <v-list-item-title class="white--text body-2 font-weight-thin"
              >Öffnungszeiten</v-list-item-title
            >
          </v-list-item>
          <v-divider color="white"></v-divider>
          <v-list-item to="/ueberuns">
            <v-list-item-title class="white--text body-2 font-weight-thin"
              >Über Uns</v-list-item-title
            >
          </v-list-item>
          <v-divider color="white"></v-divider>
          <v-list-item to="/impressum">
            <v-list-item-title class="white--text body-2 font-weight-thin"
              >Impressum</v-list-item-title
            >
          </v-list-item>
          <v-divider color="white"></v-divider>
          <v-list-item to="/datenschutz">
            <v-list-item-title class="white--text body-2 font-weight-thin"
              >Datenschutz</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <br />
    <br />
    <v-footer color="#212121" padless>
      <v-row justify="center" no-gutters>
        <router-link to="/ueberuns" style="text-decoration: none" class="mt-4">
          <span class="mr-10 white--text">Über Uns</span>
        </router-link>
        <router-link to="/" style="text-decoration: none" class="mt-4">
          <span class="mr-10 white--text">Home</span>
        </router-link>
        <router-link
          to="/speisekartenew"
          style="text-decoration: none"
          class="mt-4"
        >
          <span class="mr-10 white--text">Speisekarte</span>
        </router-link>
        <router-link to="/kontakt" style="text-decoration: none" class="mt-4">
          <span class="mr-10 white--text">Abholung & Reservierung</span>
        </router-link>
        <router-link to="/impressum" style="text-decoration: none" class="mt-4">
          <span class="mr-10 white--text">Impressum</span>
        </router-link>
        <router-link to="/datenschutz" style="text-decoration: none" class="mt-4">
          <span class="mr-10 white--text">Datenschutz</span>
        </router-link>
        <!-- <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link }}
        </v-btn> -->
        <v-col class="#212121 py-4 text-center white--text" cols="12">
          © {{ new Date().getFullYear() }} — <strong>Café am Platz</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    dialog: true,
    drawer: null,
    group: null,
    links: ['Home', 'About Us', 'Team', 'Services', 'Blog', 'Contact Us'],
  }),
    methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>
<style lang="scss" scoped></style>
