<template>
  <div class="container">
    <div class="container mb-2">    
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">Side Dishes</h1>
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
    <!-- <backtospeisekarte></backtospeisekarte> -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: { Backtospeisekarte },
  name: 'Beilagen',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Beilage',
          value: 'speise',
        },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        // {
        //   id: 1,
        //   speise: 'REIS | rice',
        //   preis: '3,90 €',
        // },
        {
          id: 2,
          speise: 'GRÜNER SALAT | salad',
          preis: '4,60 €',
        },
        {
          id: 3,
          speise: 'GEMISCHTER SALAT | mixed salad',
          preis: '5,40 €',
        },
        {
          id: 4,
          speise: 'BRATERDÄPFELN | fried potatoes',
          preis: '4,70 €',
        },
        {
          id: 5,
          speise: 'POMMES | fries',
          preis: '4,80 €',
        },
        {
          id: 6,
          speise: 'PETERSILERDÄPFELN | parsley potatoes',
          preis: '4,70 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
