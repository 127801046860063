<template>
  <div class="container">
    <h1 class="text-center display-3 font-weight-thin mt-8">
      Omlette & Leichte Speisen
    </h1>
    <div class="container text-center">
    <v-divider class="mx-4 mt-6 mb-4" color="black"></v-divider>
      <p class="font-italic">
        <b>OMLETTE & LEICHTE SPEISEN - BIS 16:00 Uhr</b> <br />OMLETTES & LIGHT
        DISHES - until 4:00 pm
      </p>
    <v-divider class="mx-4" color="black"></v-divider>
    </div>
    <!-- <backtospeisekarte></backtospeisekarte> -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.picture="{ item }">
          <v-img
            :src="require(`../assets/images/${item.picture}`)"
            max-width="150"
          ></v-img>
        </template>
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: { Backtospeisekarte },
  name: 'Omelette',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Speise',
          value: 'speise',
        },
        { text: 'Picture', value: 'picture' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          speise: 'SCHINKEN ODER KÄSEOMLETTE | HAM OR CHEESE OMLETTE',
          picture: 'omlette.png',
          allergene: '(C, G, O)',
          inhalt:
            'Tomaten, Zwiebel, Speck, Paprika | tomatoes, onion, bacon, bell pepper',
          preis: '8,90 €',
        },
        {
          id: 2,
          speise: 'LACHSTELLER FEIN GARNIERT',
          picture: 'lachsteller.png',
          allergene: '(A, D, G)',
          inhalt:
            'Lachs, Gervais, Semmel & Kornspitz | smoked salmon, cream cheese, roll & grain bun',
          preis: '12,90 €',
        },
        {
          id: 3,
          speise: 'HUMMUS-TELLER FEIN GARNIERT',
          picture: 'hummusteller.png',
          allergene: '(A, H, O, N)',
          inhalt:
            'Hummus, Antipasti, Semmel & Kornspitz | hummus, antipasti, roll & grain bun',
          preis: '10,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
