import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
// import Speisekarte from '../views/Speisekarte.vue';
import Fruehstueckauswahl from '../views/Fruehstueckauswahl.vue';
import Fruehstueck from '../views/Fruehstueck.vue';
import Fruehstueckextras from '../views/Fruehstueckextras.vue';
import Snacks from '../views/Snacks.vue';
import Omelette from '../views/Omelette.vue';
import Suppe from '../views/Suppe.vue';
import Hauptspeise from '../views/Hauptspeise.vue';
import Beilagen from '../views/Beilagen.vue';
import Nachspeise from '../views/Nachspeise.vue';
import Oeffnungszeiten from '../views/Oeffnungszeiten.vue';
// import Getraenkekarte from '../views/Getraenkekarte.vue';
import Alkoholfrei from '../views/Alkoholfrei.vue';
import Jugendgetraenke from '../views/Jugendgetraenke.vue';
import Warmegetraenke from '../views/Warmegetraenke.vue';
import Kaffee from '../views/Kaffee.vue';
import Tee from '../views/Tee.vue';
import Schokolade from '../views/Schokolade.vue';
import Bier from '../views/Bier.vue';
import Weine from '../views/Weine.vue';
import Spirituosen from '../views/Spirituosen.vue';
import Impressum from '../views/Impressum.vue';
import Kontakt from '../views/Kontakt.vue';
import Allergene from '../views/Allergene.vue';
import Ueberuns from '../views/Ueberuns.vue';
import Speisekartenew from '../views/Speisekartenew.vue';
import Getraenkekartenew from '../views/Getraenkekartenew.vue';
import Kaffeenew from '../views/Kaffeenew.vue';
import Datenschutz from '../views/Datenschutz.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/speisekarte',
  //   name: 'Speisekarte',
  //   component: Speisekarte,
  // },
  {
    path: '/fruehstueckauswahl',
    name: 'Fruehstueckauswahl',
    component: Fruehstueckauswahl,
  },
  {
    path: '/fruehstueck',
    name: 'Fruehstueck',
    component: Fruehstueck,
  },
  {
    path: '/fruehstueckextras',
    name: 'Fruehstueckextras',
    component: Fruehstueckextras,
  },
  {
    path: '/snacks',
    name: 'Snacks',
    component: Snacks,
  },
  {
    path: '/omelette',
    name: 'Omelette',
    component: Omelette,
  },
  {
    path: '/suppe',
    name: 'Suppe',
    component: Suppe,
  },
  {
    path: '/hauptspeise',
    name: 'Hauptspeise',
    component: Hauptspeise,
  },
  {
    path: '/beilagen',
    name: 'Beilagen',
    component: Beilagen,
  },
  {
    path: '/nachspeise',
    name: 'Nachspeise',
    component: Nachspeise,
  },
  {
    path: '/oeffnungszeiten',
    name: 'Oeffnungszeiten',
    component: Oeffnungszeiten,
  },
  // {
  //   path: '/getraenkekarte',
  //   name: 'Getraenkekarte',
  //   component: Getraenkekarte,
  // },
  {
    path: '/alkoholfrei',
    name: 'Alkoholfrei',
    component: Alkoholfrei,
  },
  {
    path: '/jugendgetraenke',
    name: 'Jugendgetraenke',
    component: Jugendgetraenke,
  },
  {
    path: '/warmegetraenke',
    name: 'Warmegetraenke',
    component: Warmegetraenke,
  },
  {
    path: '/kaffee',
    name: 'Kaffee',
    component: Kaffee,
  },
  {
    path: '/tee',
    name: 'Tee',
    component: Tee,
  },
  {
    path: '/schokolade',
    name: 'Schokolade',
    component: Schokolade,
  },
  {
    path: '/bier',
    name: 'Bier',
    component: Bier,
  },
  {
    path: '/weine',
    name: 'Weine',
    component: Weine,
  },
  {
    path: '/spirituosen',
    name: 'Spirituosen',
    component: Spirituosen,
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt,
  },
  {
    path: '/allergene',
    name: 'Allergene',
    component: Allergene,
  },
  {
    path: '/ueberuns',
    name: 'Ueberuns',
    component: Ueberuns,
  },
  {
    path: '/speisekartenew',
    name: 'Speisekartenew',
    component: Speisekartenew,
  },
  {
    path: '/getraenkekartenew',
    name: 'Getraenkekartenew',
    component: Getraenkekartenew,
  },
  {
    path: '/kaffeenew',
    name: 'Kaffeenew',
    component: Kaffeenew,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
    {
    path: '/Datenschutz',
    name: 'Datenschutz',
    component: Datenschutz,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
