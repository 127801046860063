<template>
  <div class="home">
    <div class="container">
      <br />
      <p class="display-2 font-weight-thin">Datenschutz</p>
      <br />
      <p class="ml-6 font-weight-bold">
        Unsere Website
      </p>
      <p class="ml-6">
        Bei unserer Website handelt es sich um eine reine "Front-End" Website,
        d.h. dass die Website nur dem ausschließlichem Zweck der Darstellung
        unserer Gerichte um unseren Kunden eine erleichterte Darstellung unserer
        Gerichte zu ermöglichen, dient. <br />
        Ferner dient die Website noch der digitalen Darstellung unserer
        physischen Speisekarte. <br />
        Die Website enthält keine "back-end" Programmierung und speichert weder
        Nutzerdaten noch welche Daten auch immer.
      </p>
      <p class="ml-6 font-weight-bold">
        Ihre Daten
      </p>
      <p class="ml-6">
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen.
        <br />
        Ihre Daten werden jedoch, wie bereits oben erwähnt, nicht von uns
        verarbeitet. <br />
        Unsere Website dient dem ausschließlichem Zweck einer digitalen
        Speisekarte und stellt somit nur die in unserem Restaurant erhältlichen
        Gericht dar.
      </p>
      <p class="ml-6 font-weight-bold">
        Kontakt mit uns
      </p>
      <p class="ml-6">
        Falls Sie per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen
        Daten sofort nach Beantwortung der Anfrage gelöscht. <br />
        Diese Daten geben wir nicht ohne Ihre Einwilligung, bevor sie bei uns
        gelöscht werden, weiter.
      </p>
      <p class="ml-6 font-weight-bold">
        Cookies
      </p>
      <p class="ml-6">
        Unsere Website verwendet keine Cookies. <br />
        Was aber höchstwahrscheinlich der Fall ist, ist, dass der Browser auf
        Ihrem Endgerät automatisch, nachdem Sie unsere Website einmal besucht
        haben, Daten (wie Texte und Bilder) der Website im Cache speichert. <br />
        Dies soll nur eine verkürzte Ladezeit der Daten bei Ihrem nächsten
        Besuch auf unserer Website ermöglichen und richtet keinen Schaden an.
        <br />
        Sie können aber die Daten, die unter Cache gespeichert sind unter
        Einstellungen auf Ihrem Endgerät, freilich löschen.
      </p>
      <p class="ml-6 font-weight-bold">
        Analytics
      </p>
      <p class="ml-6">
        Da unsere Website nur der Bereitstellung unserer Speisekarte und die
        Darstellung unserer Gerichte dient, wurde auch keine Analytic-Tool in
        welcher Art und Weise auch immer eingeschalten, um Ihre Daten zu
        analysieren.
      </p>
      <p class="ml-6 font-weight-bold">
        Links
      </p>
      <p class="ml-6">
        Wir übernehmen keine Verantwortung, Gewährleistung oder Haftung für die
        Inhalte von externen Websites, die über Links von dieser Website
        erreicht werden können (z.B. Instagram), da die ständige inhaltliche Überprüfung aller
        Links nicht möglich ist.
      </p>
      <p class="ml-6 font-weight-bold">Copyright © Cafe am Platz</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
