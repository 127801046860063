<template>
  <div class="container">
    <div class="container mb-2">    
    <v-divider class="mx-4" color="black"></v-divider>
    <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">Breakfast</h1>
    <v-divider class="mx-4" color="black"></v-divider>
    </div>
    <backtospeisekarte></backtospeisekarte>
    <div class="d-flex flex-wrap">
      <v-row>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/fruehstueck"
            style="background-color: #212121"
          >
            <v-img
              src="../assets/images/cafeamplatzfruehstueck.png"
              height="220px"
            ></v-img>
            <v-card-title class="justify-center font-weight-thin">
              FRÜHSTÜCK | BREAKFAST
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="24" sm="12" md="6">
          <v-card
            class="mx-auto white--text"
            max-width="344"
            to="/fruehstueckextras"
            style="background-color: #212121"
          >
                      <v-img
              src="../assets/images/fruehstueckextra.png"
              height="220px"
            ></v-img>
            <v-card-title class="justify-center font-weight-thin">
              FRÜHSTÜCK EXTRAS
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  components: { Backtospeisekarte },
  name: 'Fruehstueckauswahl',
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
