<template>
  <div class="container">
    <!-- <v-divider class="mx-4 mb-2" color="black"></v-divider> -->
    <h1 class="text-center display-3 font-weight-thin mb-6 mt-4">Extras</h1>
    <!-- <v-divider class="mx-4 mt-2" color="black"></v-divider> -->
    <!-- <backtofruehstueckauswahl></backtofruehstueckauswahl> -->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtofruehstueckauswahl from './Backtofruehstueckauswahl.vue';
export default {
  // components: { Backtofruehstueckauswahl },
  name: 'Fruehstueckextras',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Speise',
          value: 'speise',
        },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          speise: 'weiches Ei | soft boiled egg',
          allergene: '(C)',
          preis: '1,70 €',
        },
        {
          id: 2,
          speise: 'weiches Ei im Glas | soft boiled egg in a glass',
          allergene: '(C)',
          preis: '2,40 €',
        },
        {
          id: 3,
          speise: 'Spiegelei | egg sunny side up',
          allergene: '(C)',
          preis: '2,30 €',
        },
        {
          id: 4,
          speise:
            'Eierspeise (2 Eier) mit Schnittlauch | scrabled eggs (two) with chives',
          allergene: '(C)',
          preis: '4,50 €',
        },
        {
          id: 5,
          speise: 'ham & eggs (2 Eier)',
          allergene: '(C, O)',
          preis: '6,70 €',
        },
        {
          id: 6,
          speise: 'bacon & eggs (2 Eier)',
          allergene: '(C, O)',
          preis: '7,30 €',
        },
        {
          id: 7,
          speise: 'Port. Schinken | ham',
          allergene: '(O)',
          preis: '4,90 €',
        },
        {
          id: 8,
          speise: 'Port. Käse | cheese',
          allergene: '(G, O)',
          preis: '5,10 €',
        },
        {
          id: 9,
          speise: 'Port. Schinken & Käse | ham & cheese',
          allergene: '(G, O)',
          preis: '5,10 €',
        },
        {
          id: 10,
          speise: 'Fruchtsalat | fruit salad',
          allergene: '(O)',
          preis: '5,90 €',
        },
        {
          id: 11,
          speise: 'Butter | butter',
          allergene: '(G)',
          preis: '1,70 €',
        },
        // {
        //   id: 12,
        //   speise: 'Leberaufstrich | meat spread',
        //   allergene: '(H, O)',
        //   preis: '2,10 €',
        // },
        {
          id: 13,
          speise: 'Marmelade | jam',
          allergene: '(O)',
          preis: '1,90 €',
        },
        {
          id: 14,
          speise: 'Nutella',
          allergene: '(H, O)',
          preis: '2,50 €',
        },
        {
          id: 15,
          speise: 'Honig',
          allergene: '(O)',
          preis: '1,90 €',
        },
        {
          id: 16,
          speise: 'Frischkäse | cream cheese',
          allergene: '(G, O)',
          preis: '2,50 €',
        },
        {
          id: 17,
          speise: 'Hummus',
          allergene: '(H, N)',
          preis: '2,90 €',
        },
        {
          id: 18,
          speise: 'Semmel | roll',
          allergene: '(A)',
          preis: '1,80 €',
        },
        {
          id: 19,
          speise: 'Kornspitz/Vollkornbrot | grain bun/whole grain bread',
          allergene: '(A, G, F, N)',
          preis: '2,10 €',
        },
        {
          id: 20,
          speise: 'Croissant',
          allergene: '(A, G)',
          preis: '2,50 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.GetBreakfast();
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
