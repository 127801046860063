<template>
  <div class="container">
    <div class="container mb-2">    
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    <h1 class="text-center display-3 font-weight-thin mt-2 mb-2">Hot Chocolate</h1>
    <!-- <v-divider class="mx-4" color="black"></v-divider> -->
    </div>
    <!-- <backtowarmegetraenke></backtowarmegetraenke> -->
    <v-card class="mt-2">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="green"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="breakfast"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import Backtowarmegetraenke from './Backtowarmegetraenke.vue';
export default {
  // components: { Backtowarmegetraenke },
  name: 'Schokolade',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Heiße Schokolade',
          value: 'speise',
        },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      breakfast: [
        {
          id: 1,
          speise: 'Heiße Schokolade | hot chocolate',
          allergene: '(G)',
          preis: '4,40 €',
        },
        {
          id: 2,
          speise:
            'Heiße Schokolade mit Schlag | hot chocolate with whipped cream',
          allergene: '(G)',
          preis: '5,20 €',
        },
        {
          id: 3,
          speise: 'Heiße Schokolade mit Rum | hot chocolate with rum',
          allergene: '(G)',
          preis: '6,50 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
